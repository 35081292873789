export const DISTRIBUTOR_PROFILE_VI = {
  name: 'Họ tên',
  ownerName: 'Tên chủ sở hữu',
  ownerEmail: 'Email chủ sở hữu',
  ownerPhone: 'Số điện thoại chủ sở hữu',
  contactName: 'Tên liên hệ',
  contactPhone: 'Số điện thoại liên hệ',
  billingAddressDetail: 'Địa chỉ cụ thể',
  shippingAddressDetail: 'Địa chỉ cụ thể',
  isActive: 'Trạng thái',
  bankName: 'Tên ngân hàng',
  bankAccountNumber: 'Số tài khoản ngân hàng',
  bankAccountName: 'Tên chủ tài khoản ngân hàng',
  bankBranchCode: 'Mã chi nhánh',
  bankCollectionName: 'Tên ngân hàng',
  bankCollectionAccountNumber: 'Số tài khoản ngân hàng',
  bankCollectionAccountName: 'Tên chủ tài khoản ngân hàng',
  bankCollectionBranchCode: 'Mã chi nhánh',
  taxCode: 'Mã số thuế',
  type: 'Nhà phân phối',
  mailGroups: 'Email nhóm',
  brandIDs: 'Thương hiệu',
  brandGroupIDs: 'Nhóm thương hiệu',
  categoryIDs: 'Nhà phân phối thuộc danh mục',
  industry: 'Ngành hàng',
  accountantEmail: 'Email kế toán',
  billingAddressCoordinateLat: 'Vĩ độ',
  billingAddressCoordinateLong: 'Kinh độ',
  shippingAddressCoordinateLat: 'Vĩ độ',
  shippingAddressCoordinateLong: 'Kinh độ',

  distributorProfileManagement: 'Quản lý hồ sơ nhà phân phối',
  distributorName: 'Tên nhà phân phối',
  distributorCode: 'Mã',
  classificationDistributor: 'Nhà phân phối',
  normal: 'Thường',
  masterDistributorHub: 'Trung tâm Phân phối chính',
  category: 'Nhà phân phối thuộc danh mục',
  coordinate: 'Thông tin tọa độ',
  note: 'Ghi chú',
  brandDistributor: 'Các nhãn hàng chuyên trách',
  license: 'Giấy phép',
  distributorBrandGroupID: 'Distributor Brand Group',
  distributorBrandID: 'Thương hiệu nhà phân phối chuyên trách',
  isDistributorBrand: 'Nhà phối chuyên trách',
  separateFulfillmentOrder: 'Tách đơn giao hàng',
  distributorRegister: 'Quản lý đăng ký nhà phân phối',
  all: 'TẤT CẢ',
  need_approve: 'CẦN DUYỆT',
  approved: 'ĐÃ DUYỆT',
  rejected: 'TỪ CHỐI',
  active: 'HOẠT ĐỘNG',
  'in-active': 'NGƯNG HOẠT ĐỘNG',
}
