import dayjs from 'dayjs'
import { Picklist } from 'interfaces/picklist'

import {
  ORDER_ITEM_STATUS_DELIVERED,
  ORDER_ITEM_STATUS_DELIVERY_FAILED,
} from './order'

export const PICKLIST_STATUS_CREATED = 'created'
export const PICKLIST_STATUS_CHECKING = 'checking'
export const PICKLIST_STATUS_PICKING = 'picking'
export const PICKLIST_STATUS_COMPLETED = 'completed'
export const PICKLIST_STATUS_INVOICED = 'invoiced'
export const PICKLIST_STATUS_CANCELLED = 'cancelled'
export const PICKLIST_STATUS_IRN_GENERATING = 'irn_generating'
export const PICKLIST_STATUS_IRN_GENERATED = 'irn_generated'

export const PICKLIST_ITEM_STATUS_CREATED = 'created'
export const PICKLIST_ITEM_STATUS_CHECKING = 'checking'
export const PICKLIST_ITEM_STATUS_PICKING = 'picking'
export const PICKLIST_ITEM_STATUS_APPROVED = 'approved'
export const PICKLIST_ITEM_STATUS_REJECTED = 'rejected'
export const CGST = 'cgstPercent'
export const SGST = 'sgstPercent'
export const CESS = 'cessPercent'
export const IGST = 'igstPercent'

export const E_BILL_INFO_STATUS_NOT_SENT = 'not_sent'
export const E_BILL_INFO_STATUS_SUCCESS = 'success'
export const E_BILL_INFO_STATUS_FAILED = 'failed'

export const INVOICE_UPDATED_YES = 'invoice_updated_yes'
export const INVOICE_UPDATED_NO = 'invoice_updated_no'

export const INBOUND_STATUS = {
  CREATED: 'created',
  DISTRIBUTOR_APPROVED: 'distributor_approved',
  FINANCE_APPROVED: 'finance_approved',
  REJECTED: 'rejected',
  BGM_CONFIRMED: 'bgm_confirmed',
}

export const TAX_RECEIPT_MAPPING = {
  [CESS]: 'cess',
  [CGST]: 'cgst',
  [SGST]: 'sgst',
  [IGST]: 'igst',
}

export const HAS_INVOICE_SELECT = {
  [INVOICE_UPDATED_YES]: 'Yes',
  [INVOICE_UPDATED_NO]: 'No',
}

export const PICKLIST_STATUS_COMPLETED_AND_INVOICED_GENERATE_IRN = [
  PICKLIST_STATUS_COMPLETED,
  PICKLIST_STATUS_INVOICED,
]

export const PICKLIST_STATUS_TRANSITION = {
  [PICKLIST_STATUS_CREATED]: [
    PICKLIST_STATUS_PICKING,
    PICKLIST_STATUS_CANCELLED,
  ],
  [PICKLIST_STATUS_PICKING]: [
    PICKLIST_STATUS_COMPLETED,
    PICKLIST_STATUS_CANCELLED,
  ],
}
export const PICKLIST_ITEM_STATUS_TRANSITION = {
  [PICKLIST_ITEM_STATUS_CREATED]: [
    PICKLIST_ITEM_STATUS_APPROVED,
    PICKLIST_ITEM_STATUS_REJECTED,
  ],
}

export const PICKLIST_STATUS_COLOR_MAPPING = {
  [PICKLIST_STATUS_CREATED]: 'blue',
  [PICKLIST_STATUS_PICKING]: 'orange',
  [PICKLIST_STATUS_IRN_GENERATED]: 'orange',
  [PICKLIST_STATUS_COMPLETED]: 'success',
  [PICKLIST_STATUS_CANCELLED]: 'red',
  [PICKLIST_STATUS_INVOICED]: 'default',
}

export const PICKLIST_E_BILL_INFO_STATUS_COLOR_MAPPING = {
  [E_BILL_INFO_STATUS_SUCCESS]: 'green',
  [E_BILL_INFO_STATUS_NOT_SENT]: 'orange',
  [E_BILL_INFO_STATUS_FAILED]: 'red',
}

export const PICKLIST_ITEM_STATUS_COLOR_MAPPING = {
  [PICKLIST_ITEM_STATUS_CREATED]: 'blue',
  [PICKLIST_ITEM_STATUS_APPROVED]: 'success',
  [PICKLIST_ITEM_STATUS_REJECTED]: 'error',
}
export const PICKLIST_STATUS_SELECT_INDIA = {
  [PICKLIST_STATUS_CREATED]: 'CREATED',
  [PICKLIST_STATUS_CHECKING]: 'CHECKING',
  [PICKLIST_STATUS_IRN_GENERATING]: 'IRN GENERATING',
  [PICKLIST_STATUS_IRN_GENERATED]: 'IRN GENERATED',
  [PICKLIST_STATUS_PICKING]: 'PICKING',
  [PICKLIST_STATUS_COMPLETED]: 'COMPLETED',
  [PICKLIST_STATUS_CANCELLED]: 'CANCELLED',
  [PICKLIST_STATUS_INVOICED]: 'INVOICED',
}

export const PICKLIST_STATUS_SELECT_FILTER_INDIA = {
  [PICKLIST_STATUS_CREATED]: 'CREATED',
  [PICKLIST_STATUS_CHECKING]: 'CHECKING',
  [PICKLIST_STATUS_PICKING]: 'PICKING',
  [PICKLIST_STATUS_COMPLETED]: 'COMPLETED',
  [PICKLIST_STATUS_CANCELLED]: 'CANCELLED',
  [PICKLIST_STATUS_INVOICED]: 'INVOICED',
}

export const PICKLIST_E_BILL_INFO_STATUS = {
  [E_BILL_INFO_STATUS_SUCCESS]: 'DONE',
  [E_BILL_INFO_STATUS_NOT_SENT]: 'NOT YET',
  [E_BILL_INFO_STATUS_FAILED]: 'FAILED',
}

export const PICKLIST_STATUS = [
  PICKLIST_STATUS_CREATED,
  PICKLIST_STATUS_CHECKING,
  PICKLIST_STATUS_PICKING,
  PICKLIST_STATUS_COMPLETED,
  PICKLIST_STATUS_CANCELLED,
]
export const PICKLIST_STATUS_TRANSLATE_MAPPING = {
  [PICKLIST_STATUS_CREATED]: 'Đơn mới',
  [PICKLIST_STATUS_CHECKING]: 'Đang in đơn lẻ',
  [PICKLIST_STATUS_PICKING]: 'Đang in đơn tổng',
  [PICKLIST_STATUS_COMPLETED]: 'Hoàn thành',
  [PICKLIST_STATUS_CANCELLED]: 'Đơn đã hủy',
}

export const PICKLIST_ORDER_ITEM_STATUS_TRANSLATE_MAPPING = {
  [ORDER_ITEM_STATUS_DELIVERED]: 'Hoàn thành',
  [ORDER_ITEM_STATUS_DELIVERY_FAILED]: 'Giao thất bại',
}

export type ExportPicklistType = 'csv' | 'xlsx' | 'xlsx_complete'

export const EXPORT_PICKLIST_CSV = 'csv'
export const EXPORT_PICKLIST_XLSX = 'xlsx'
export const EXPORT_PICKLIST_XLSX_COMPLETE = 'xlsx_complete'

export const EXPORT_PICKLIST_TYPES = {
  [EXPORT_PICKLIST_CSV]: 'Export to CSV',
  [EXPORT_PICKLIST_XLSX]: 'Export to MS Excel',
  [EXPORT_PICKLIST_XLSX_COMPLETE]: 'Export to MS Excel and complete',
}

const roundUpto500 = (amount: number) => {
  const roundedAmount = Math.round(amount)
  // redundancy
  const r = roundedAmount % 1000
  if (r === 0) {
    return roundedAmount
  }
  const roundedValue = r >= 500 ? 1000 : 500
  return roundedAmount - r + roundedValue
}

export const buildPicklistOrderExportData = (picklists: Picklist[]) => {
  const data = picklists.reduce((prev, picklist) => {
    const { order, items, warehouse, misaInvoiceUrl } = picklist
    const {
      createdAt,
      tradeOrderCreatedAt,
      tradeOrderNumber,
      agent,
      receiver,
      isNewCustomer,
      remark,
      shippingFee,
    } = order || {}
    const orderCreatedDate = tradeOrderCreatedAt
      ? dayjs(tradeOrderCreatedAt).format('DD-MM-YYYY HH:mm:ss')
      : ''

    const baseObj = {
      'Ngày Order': createdAt ? dayjs(createdAt).format('DD-MM-YYYY') : '',
      'Order No.': tradeOrderNumber,
      'Đường dẫn hóa đơn điện tử': misaInvoiceUrl || '',
      NVBH: agent?.code,
      'Tên NVBH': agent?.name,
      'SĐT NVBH': agent?.phone,
      'Mã KH': receiver?.code,
      'Tên KH': receiver?.name,
      'Mã số thuế': receiver?.taxNumber,
      'Địa chỉ trên giấy phép': receiver?.addressOnLicense,
      Email: receiver?.email,
    }

    const totalAdjustQty = items?.reduce(
      (prev, curr) => prev + curr?.orderItem?.adjustQuantity,
      0
    )

    const nextItems = items.map(
      ({ orderItem, orderItemInventoryMovements }) => {
        const {
          sku,
          name,
          unitPrice,
          product,
          promotionDiscount,
          productType,
          vigoCommission,
          salesCommissionAmount,
          cogs,
          status,
          distributorPriceSubsidy,
          saSuccessFee,
          adjustQuantity,
          agentPriceSubsidy,
          quantity,
          purchaseQuantity,
        } = orderItem || {}
        const ratioQty =
          purchaseQuantity !== 0 ? adjustQuantity / purchaseQuantity : 0
        // average SaSuccessFee
        const avgSaSuccessFee =
          adjustQuantity !== 0 && purchaseQuantity > 0
            ? saSuccessFee / purchaseQuantity
            : 0
        //Giá bán
        const price = unitPrice + avgSaSuccessFee
        // Khuyến mãi
        const promoByAdjustQty = promotionDiscount * ratioQty
        // Thành tiền
        const subtotal =
          adjustQuantity !== 0
            ? Math.round(
                adjustQuantity * (avgSaSuccessFee + unitPrice) -
                  promoByAdjustQty
              )
            : 0
        //tổng tiền hổ trợ
        const agentSubsidyByAdjustQty = agentPriceSubsidy * ratioQty

        const disSubsidyByAdjustQty = distributorPriceSubsidy * ratioQty

        const totalSubsidy =
          agentSubsidyByAdjustQty + disSubsidyByAdjustQty || 0

        //Phí giao hàng
        const shippingFeeItem = (
          shippingFee *
          (adjustQuantity / (totalAdjustQty || 1))
        ).toFixed(2)
        // Giá sau hỗ trợ từ NVBH
        const subtotalIncludingFee =
          adjustQuantity !== 0
            ? Math.round(
                Math.round(subtotal - totalSubsidy) +
                  parseFloat(shippingFeeItem)
              )
            : 0
        const roundedSubtotalIncludingFee = roundUpto500(subtotalIncludingFee)
        // Hỗ trợ từ NVBH
        const supportFee = Math.max(subtotal - subtotalIncludingFee, 0) // prevent negative value
        // Hỗ trợ từ NPP
        const supportFeeDistributor = Math.round(distributorPriceSubsidy || 0)
        const batchNumber = orderItemInventoryMovements
          ?.map((item) => item?.physicalBatchNumber || '')
          .toString()
        const expirationDate = orderItemInventoryMovements
          ?.map((item) => {
            return item?.additionalInfo?.expirationDate
              ? dayjs(item?.additionalInfo?.expirationDate).format('DD-MM-YYYY')
              : ''
          })
          .toString()
        return {
          ...baseObj,
          'Mã SP': sku,
          'Tên SP': name,
          'Mã Dược Quốc Gia':
            orderItemInventoryMovements?.[0]?.additionalInfo?.nationalDrugCode,
          'Số Lô': batchNumber,
          'Hạn dùng': expirationDate,
          ĐVT: product?.unit, // eslint-disable-line
          'Giá Bán': price,
          'Số Lượng': quantity,
          'Số Lượng Đã Giao': adjustQuantity,
          'Thành Tiền': subtotal,
          'Hỗ Trợ Từ NVBH': supportFee,
          'Giá Sau Hỗ Trợ Từ NVBH': subtotalIncludingFee,
          'Giá Sau Hỗ Trợ Từ NVBH Làm Tròn': roundedSubtotalIncludingFee,
          'Trợ giá từ Nhà phân phối': supportFeeDistributor,
          'Trợ giá tự động':
            orderItem?.distributorSubsidy?.autoSubsidyAmount || 0,
          'Trợ giá thủ công':
            orderItem?.distributorSubsidy?.manualSubsidyAmount || 0,
          'Trợ giá đặc biệt':
            orderItem?.distributorSubsidy?.specialSubsidyAmount || 0,
          'Khuyến mãi giảm giá': promoByAdjustQty,
          'Thời gian tạo': orderCreatedDate,
          'Ghi chú': remark,
          'SĐT KH': receiver?.phone,
          'Địa chỉ giao hàng': receiver?.address?.detail,
          'Quy cách': product?.pack,
          'Khách hàng mới': isNewCustomer ? 'Yes' : 'No',
          Kho: warehouse?.code,
          'Trạng thái': PICKLIST_STATUS_TRANSLATE_MAPPING[picklist.status],
          'Trạng thái giao hàng':
            status === ORDER_ITEM_STATUS_DELIVERED ||
            status === ORDER_ITEM_STATUS_DELIVERY_FAILED
              ? PICKLIST_ORDER_ITEM_STATUS_TRANSLATE_MAPPING[status]
              : 'Đang xử lý',
          'Loại sản phẩm': productType,
          'Đơn giá vốn': cogs,
          'Đơn giá Listing': vigoCommission,
          'Phí dịch vụ bán hàng thành công': saSuccessFee
            ? saSuccessFee
            : salesCommissionAmount,
          'Phí giao hàng': parseFloat(shippingFeeItem),
        }
      }
    )

    return [...prev, ...nextItems]
  }, [])

  return data
}
