import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Divider, Form, Input } from 'antd'
import ErrorAlert from 'components/ErrorAlert'
import LocaleSwitcher from 'components/LocaleSwitcher'
import Head from 'next/head'
import router from 'next/router'
import { getSession, signIn } from 'next-auth/react'
import { useTranslations } from 'next-intl'
import React, { useState } from 'react'
import { isIndia } from 'utils/commonUtils'
import { COMMON } from 'utils/localUtils'
import {
  BACK_END,
  EVENT_LOGIN,
  EVENT_VIEW_LOGIN_FORM,
  FRONT_END,
  tracking,
  VIEW_LIST,
} from 'utils/tracking'
import {
  MAPPING_LOGIN_FAILED,
  MAPPING_LOGIN_SUCCESS,
} from 'utils/tracking/pages/login'

interface LoginFormValues {
  username: string
  password: string
}

const googleIcon = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 7.1575C14 6.69667 13.9583 6.25917 13.8868 5.83333H7.15198V8.46417H11.0081C10.8352 9.3275 10.3287 10.0567 9.57769 10.5525V12.3025H11.8782C13.2252 11.0833 14 9.28667 14 7.1575Z"
      fill="#4285F4"
    />
    <path
      d="M7.15198 14C9.08301 14 10.6982 13.37 11.8782 12.3025L9.57769 10.5525C8.93401 10.9725 8.1175 11.2292 7.15198 11.2292C5.2865 11.2292 3.70711 9.99833 3.14091 8.33583H0.768838V10.1383C1.94295 12.425 4.35675 14 7.15198 14Z"
      fill="#34A853"
    />
    <path
      d="M3.14091 8.33583C2.99191 7.91583 2.91443 7.46667 2.91443 7C2.91443 6.53333 2.99787 6.08417 3.14091 5.66417V3.86167H0.768838C0.280119 4.80667 0 5.86833 0 7C0 8.13167 0.280119 9.19333 0.768838 10.1383L3.14091 8.33583Z"
      fill="#FBBC05"
    />
    <path
      d="M7.15198 2.77083C8.2069 2.77083 9.14857 3.12667 9.89357 3.82083L11.9319 1.82583C10.6982 0.694167 9.08301 0 7.15198 0C4.35675 0 1.94295 1.575 0.768838 3.86167L3.14091 5.66417C3.70711 4.00167 5.2865 2.77083 7.15198 2.77083Z"
      fill="#EA4335"
    />
  </svg>
)

export const LoginForm: React.FC = () => {
  const [form] = Form.useForm()
  const [error, setError] = useState('')

  const t = useTranslations(COMMON)

  const handleSubmit = async ({ username, password }: LoginFormValues) => {
    setError('')
    const res = await signIn('credentials', {
      username,
      password,
      redirect: false,
    })
    if (!res.ok) {
      if (!isIndia) {
        const loginErrorProperties = {
          login_method: 'password',
          is_successful: res.ok,
          error_type: BACK_END,
          error_description: [res?.error],
        }
        tracking(EVENT_LOGIN, loginErrorProperties, MAPPING_LOGIN_FAILED)
      }
      setError(t('messageErrorPassword'))
    } else {
      if (!isIndia) {
        const loginSuccessProperties = {
          login_method: 'password',
          is_successful: res.ok,
        }
        tracking(EVENT_LOGIN, loginSuccessProperties, MAPPING_LOGIN_SUCCESS)
      }
    }
  }

  return (
    <div className="w-full space-y-3">
      <Form
        form={form}
        layout="vertical"
        className="w-full p-0"
        onFinish={handleSubmit}
      >
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: t('emailRequired'),
            },
            {
              type: 'email',
              message: t('validateEmail'),
            },
          ]}
        >
          <Input
            placeholder="Email"
            prefix={<UserOutlined className="text-blue" />}
          />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: t('passwordRequired'),
            },
          ]}
        >
          <Input.Password
            placeholder={t('password')}
            prefix={<LockOutlined className="text-blue" />}
          />
        </Form.Item>

        <Form.Item>
          <Button
            htmlType="submit"
            type="primary"
            className="w-full"
            onClick={() => {
              form.validateFields().catch(({ errorFields }) => {
                const errors = errorFields?.map((i) => i?.errors?.[0])
                if (errors?.length > 0 && !isIndia) {
                  const loginErrorProperties = {
                    login_method: 'password',
                    is_successful: false,
                    error_type: FRONT_END,
                    error_description: errors,
                  }
                  return tracking(
                    EVENT_LOGIN,
                    loginErrorProperties,
                    MAPPING_LOGIN_FAILED
                  )
                }
              })
            }}
          >
            {t('login')}
          </Button>
        </Form.Item>
      </Form>
      {error && <ErrorAlert error={error} onClose={() => setError('')} />}
    </div>
  )
}

export const LoginComponent = () => {
  const t = useTranslations(COMMON)

  if (!isIndia) {
    setTimeout(() => {
      const properties = { source_screen: 'organic' }
      return tracking(EVENT_VIEW_LOGIN_FORM, properties, VIEW_LIST)
    }, 500)
  }

  return (
    <>
      <Head>
        <title>Login</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>

      <div
        className="w-full h-screen flex justify-center items-center"
        style={
          !isIndia
            ? {
                backgroundImage: 'url("/Background.png")',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                minHeight: '100vh',
              }
            : {}
        }
      >
        <div className={isIndia && 'w-[40vw]'}>
          {!isIndia && (
            <div className="w-full flex justify-center items-center mb-4">
              <img className="w-[150px]" src="/logoVigoLogin.png" alt="vigo" />
            </div>
          )}
          <div className="w-full min-h-[300px] flex flex-col justify-center items-center px-5 py-6 max-w-full bg-white rounded-lg shadow-xl sm:max-w-[27rem] md:mx-auto">
            {isIndia ? (
              <h1 className="text-3xl">Welcome Back!</h1>
            ) : (
              <>
                <div className="self-end">
                  <LocaleSwitcher />
                </div>
                <div className="text-center mb-8">
                  <div>{t('welcomeToVigo')}</div>
                  <div className="text-xs text-[#8c8c8c]">
                    {t('descWelcome')}
                  </div>
                </div>
              </>
            )}

            <LoginForm />
            <Divider className="m-0 text-[#ccc]">{t('or')}</Divider>
            <Button
              className="w-full mt-4"
              size="large"
              onClick={() => signIn('google')}
            >
              <div>
                <span className="inline-block mr-1">{googleIcon}</span>{' '}
                {t('loginGoogle')}
              </div>
            </Button>
            {!isIndia && (
              <div className="mt-2">
                <span className="font-thin">{t('doNotAcc')} </span>
                <Button
                  type="link"
                  className="p-0 font-thin"
                  onClick={() => router.push('/sign-up')}
                >
                  {t('signUp')}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

const LoginPage = () => <LoginComponent />

LoginPage.getInitialProps = async (context) => {
  const { req, res } = context
  const session = await getSession({ req })
  if (session && res && session.accessToken) {
    res.writeHead(302, {
      Location: '/',
    })
    res.end()
    return
  }

  return {
    session: undefined,
  }
}
export default LoginPage
