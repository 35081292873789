export const INVENTORY_EXPORT_SUMMARY = 'inventory_export_summary'
export const INVENTORY_EXPORT_MOVEMENT = 'inventory_export_movement'
export const INVENTORY_EXPORT_BY_BATCH = 'inventory_export_by_batch'

export const INVENTORY_ENTITY_TYPE_INBOUND_ITEM = 'inbound_item'
export const INVENTORY_ENTITY_TYPE_OUTBOUND_ITEM = 'outbound_item'
export const INVENTORY_ENTITY_TYPE_UPLOAD_OVERRIDE = 'upload_override'
export const INVENTORY_ENTITY_TYPE_OVERRIDE = 'override'
export const INVENTORY_ENTITY_TYPE_ORDER_ITEM = 'order_item'
export const INVENTORY_ENTITY_TYPE_PRODUCT_LISTING = 'product_listing_v2'
export const INVENTORY_ENTITY_TYPE_PRODUCT_TRADING_STOCK =
  'product_trading_by_listing'
export const INVENTORY_ENTITY_TYPE_RETURN_ITEM = 'return_item'
export const INVENTORY_ENTITY_TYPE_TRANSFER_STOCK =
  'inventory_transfer_request_item'

export const PROCESS_TYPE_TRADING = 'trading'
export const PROCESS_TYPE_RETAIL = 'retail'
export const PROCESS_TYPE_LISTING = 'listing'
export const PROCESS_TYPE_CONSIGNMENT = 'consignment'
export const PROCESS_TYPE_TRADING_BY_LISTING = 'trading_by_listing'
export const PROCESS_TYPE_PRODUCT_LISTING = 'product_listing_v2'

export const INVENTORY_TYPE_INBOUND = 'inbound'
export const INVENTORY_TYPE_OUTBOUND = 'outbound'
export const INVENTORY_TYPE_UPLOAD_AWAITING_FOR_SALE =
  'upload_override_awaiting_for_sale'

export const INVENTORY_STATUS_AWAITING_FOR_SALE = 'awaiting_for_sale'
export const INVENTORY_STATUS_SALABLE = 'salable'
export const INVENTORY_STATUS_DAMAGED = 'damaged'
export const INVENTORY_STATUS_RESERVED = 'reserved'
export const INVENTORY_STATUS_IN_TRANSIT = 'in_transit'

export const INVENTORY_EXPORT_TYPES = {
  [INVENTORY_EXPORT_SUMMARY]: 'Export Inventory Summary',
  [INVENTORY_EXPORT_MOVEMENT]: 'Export Inventory Movement',
  [INVENTORY_EXPORT_BY_BATCH]: 'Export Inventory By Batch',
}

export const INVENTORY_ENTITY_TYPE_SELECT = [
  INVENTORY_ENTITY_TYPE_INBOUND_ITEM,
  INVENTORY_ENTITY_TYPE_OUTBOUND_ITEM,
  INVENTORY_ENTITY_TYPE_ORDER_ITEM,
  INVENTORY_ENTITY_TYPE_PRODUCT_LISTING,
  INVENTORY_ENTITY_TYPE_OVERRIDE,
  INVENTORY_ENTITY_TYPE_UPLOAD_OVERRIDE,
  INVENTORY_TYPE_UPLOAD_AWAITING_FOR_SALE,
  INVENTORY_ENTITY_TYPE_RETURN_ITEM,
  INVENTORY_ENTITY_TYPE_TRANSFER_STOCK,
]

export const INVENTORY_TYPE_SELECT = [
  INVENTORY_TYPE_INBOUND,
  INVENTORY_TYPE_OUTBOUND,
]

export const INVENTORY_PROCESS_TYPE_SELECT = [
  PROCESS_TYPE_TRADING,
  PROCESS_TYPE_CONSIGNMENT,
  PROCESS_TYPE_RETAIL,
  PROCESS_TYPE_LISTING,
  PROCESS_TYPE_TRADING_BY_LISTING,
]

export const INVENTORY_STATUS_SELECT = [
  INVENTORY_STATUS_AWAITING_FOR_SALE,
  INVENTORY_STATUS_SALABLE,
  INVENTORY_STATUS_DAMAGED,
  INVENTORY_STATUS_RESERVED,
  INVENTORY_STATUS_IN_TRANSIT,
]
