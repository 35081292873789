import {
  MARK_AS_TYPE_DELIVERY_FAILED,
  MARK_AS_TYPE_FULL_DELIVERED,
  MARK_AS_TYPE_PARTIAL_DELIVERED,
} from 'containers/Rider/Packages/ConfirmDeliveryDrawer/MarkAsDropdown'
import {
  PACKAGE_STATUS_ASSIGNED,
  PACKAGE_STATUS_ATTEMPT_FAILED,
  PACKAGE_STATUS_CANCELLED,
  PACKAGE_STATUS_DELIVERED,
  PACKAGE_STATUS_DELIVERY_FAILED,
  PACKAGE_STATUS_DISTRIBUTOR_DELIVERED,
  PACKAGE_STATUS_DISTRIBUTOR_DELIVERY_FAILED,
  PACKAGE_STATUS_DISTRIBUTOR_PARTIAL_DELIVERED,
  PACKAGE_STATUS_PARTIAL_DELIVERED,
  PACKAGE_STATUS_RETURNED,
  PACKAGE_STATUS_RIDER_DELIVERED,
  PACKAGE_STATUS_RIDER_DELIVERY_FAILED,
  PACKAGE_STATUS_RIDER_PARTIAL_DELIVERED,
  PACKAGE_STATUS_RIDER_RETURNED,
  PACKAGE_STATUS_UNASSIGNED,
} from 'utils/package'

const PACKAGE_STATUS = {
  [PACKAGE_STATUS_ASSIGNED]: 'Đã phân công',
  [PACKAGE_STATUS_ATTEMPT_FAILED]: 'Nỗ lực giao hàng thất bại',
  [PACKAGE_STATUS_CANCELLED]: 'Hủy',
  [PACKAGE_STATUS_DELIVERED]: 'Đã giao hàng',
  [PACKAGE_STATUS_DELIVERY_FAILED]: 'Giao hàng thất bại',
  [PACKAGE_STATUS_PARTIAL_DELIVERED]: 'Giao một phần',
  [PACKAGE_STATUS_RETURNED]: 'Trả hàng',
  [PACKAGE_STATUS_RIDER_DELIVERED]: 'Tài xế giao thành công',
  [PACKAGE_STATUS_RIDER_DELIVERY_FAILED]: 'Tài xế giao thất bại',
  [PACKAGE_STATUS_RIDER_PARTIAL_DELIVERED]: 'Tài xế giao một phần',
  [PACKAGE_STATUS_RIDER_RETURNED]: 'Tài xế trả hàng',
  [PACKAGE_STATUS_UNASSIGNED]: 'Chưa phân công',
  [PACKAGE_STATUS_DISTRIBUTOR_DELIVERED]: 'Nhà phân phối giao thành công',
  [PACKAGE_STATUS_DISTRIBUTOR_DELIVERY_FAILED]: 'Nhà phân phối giao thất bại',
  [PACKAGE_STATUS_DISTRIBUTOR_PARTIAL_DELIVERED]: 'Nhà phân phối giao một phần',
}

const REASON_OPTIONS = {
  dontWantAnymore: 'Không muốn nữa',
  lateDelivery: 'Giao hàng chậm trễ',
  highPrice: 'Giá cao',
  willSoonExpire: 'Hàng sắp hết hạn',
  otherReason: 'Lý do khác',
}

const MARK_AS_DROPDOWN = {
  [MARK_AS_TYPE_FULL_DELIVERED]: 'Giao toàn bộ',
  [MARK_AS_TYPE_PARTIAL_DELIVERED]: 'Giao một phần',
  [MARK_AS_TYPE_DELIVERY_FAILED]: 'Giao hàng thất bại',
}

export const PACKAGE_VI = {
  packageID: 'Mã kiện hàng',
  orderCreatedDate: 'Ngày tạo đơn hàng',
  outletPhone: 'Số điện thoại cửa hàng',
  outletAddress: 'Địa chỉ cửa hàng',
  senderName: 'Người gửi',
  senderPhone: 'Số điện thoại người gửi',
  senderAddress: 'Địa chỉ người gửi',
  confirmDelivery: 'Xác nhận trạng thái kiện hàng',
  packageDetail: 'Chi tiết kiện hàng',
  invoiceValue: 'Giá trị hóa đơn',
  deliveredQuantity: 'Số lượng đã giao',
  packageItems: 'Danh sách sản phẩm',
  exportPackage: 'Xuất dữ liệu kiện hàng',
  packageCreatedDate: 'Ngày tạo kiện hàng',
  updatePackageMsg:
    'Tổng số lượng đã giao không được bằng 0 hoặc tổng số lượng đã đặt hàng khi loại giao hàng là GIAO HÀNG MỘT PHẦN!',
  updatedByRiders: 'Cập nhật bởi tài xế',
  selectAll: 'Chọn tất cả',
  packagesSelected: 'Kiện hàng đã được chọn',
  unknownContact: 'Liên hệ không xác định',
  clearAll: 'Xóa tất cả',
  confirmAs: 'Xác nhận với trạng thái',
  markAs: 'Đánh dấu trạng thái',
  trackingNumbersSeparatedByComma:
    'Mã vận đơn (Nhiều mã cách nhau bằng dấu phẩy ",")',
  searchByTrackingNumber: 'Tìm kiếm bởi mã vận đơn',
  orderNumbersSeparatedByComma:
    'Mã đơn hàng (Nhiều mã cách nhau bằng dấu phẩy ",")',
  searchByOrderNumbers: 'Tìm kiếm bởi mã đơn hàng',
  ...PACKAGE_STATUS,
  ...REASON_OPTIONS,
  ...MARK_AS_DROPDOWN,
}
