import { DownOutlined } from '@ant-design/icons'
import { Button, Dropdown, Menu } from 'antd'
import React from 'react'
import { Translate } from 'utils/localUtils'

export const MARK_AS_TYPE_FULL_DELIVERED = 'fullDelivered'
export const MARK_AS_TYPE_PARTIAL_DELIVERED = 'partialDelivered'
export const MARK_AS_TYPE_DELIVERY_FAILED = 'deliveryFailed'

export const DROPDOWN_OPTIONS = [
  MARK_AS_TYPE_FULL_DELIVERED,
  MARK_AS_TYPE_PARTIAL_DELIVERED,
  MARK_AS_TYPE_DELIVERY_FAILED,
]

interface Props {
  className?: string
  disabled?: boolean
  onClick?: (key: string) => void
  translate: Translate
}

const MarkAsDropdown: React.FC<Props> = ({ disabled, onClick, translate }) => {
  return (
    <Dropdown
      disabled={disabled}
      overlay={
        <Menu
          onClick={(item) => {
            onClick && onClick(item.key)
          }}
        >
          {DROPDOWN_OPTIONS.map((value) => (
            <Menu.Item key={value}>{translate(`package.${value}`)}</Menu.Item>
          ))}
        </Menu>
      }
    >
      <Button>
        {translate('package.markAs')}
        <DownOutlined />
      </Button>
    </Dropdown>
  )
}

export default React.memo(MarkAsDropdown)
